import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import 'typeface-caveat/index.css';
import i18n from './plugins/i18n';
import './scss/global.scss';
import './plugins/axios';
import './plugins/services';
import './plugins/gtag';

Vue.config.productionTip = false;
new Vue({
	router,
	store,
	vuetify,
	i18n,
	render: h => h(App)
}).$mount('#app');
